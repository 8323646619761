import * as React from "react"
import OurSolutions from "../components/ourSolutions/ourSolutions"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import "../assets/css/solutions-style.css"
import { organizationSchema } from "../const/schemas.pl"
import { languages } from "../const/languages"

const ServicesPage = () => <OurSolutions lang={languages.PL} />

export const Head = () => {
  return (
    <>
      <Seo
        title="AR-Labs.io | Wybierz najlepsze rozwiązanie AR dla swojej firmy."
        description="Pozwól nam uczynić rzeczywistość rozszerzoną Twoją rzeczywistością. Nadszedł czas, aby uruchomić swoją pierwszą wirtualną przymierzalnię lub inne rozwiązanie AR jako doskonałą odpowiedź na potrzeby Twoich klientów."
        canonicalURL="https://staging-area.ar-labs.io/pl/possibilities"
      />
      <script type="application/ld+json">{organizationSchema}</script>
    </>
  )
}

export default ServicesPage
